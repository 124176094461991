var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"search-result-right",style:({ display: _vm.isMapHidden ? 'none' : 'flex' })},[_c('div',{staticClass:"map-actions"},[(_vm.currentMapCenter.lat)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isNearbyMode),expression:"!isNearbyMode"}],staticClass:"map-actions__search-while-moving"},[_c('WMCheckbox',{staticStyle:{"padding":"8px 0"},attrs:{"inline":false,"label":_vm.$t('filter.google_map.search_while_moving').toString(),"simple":true},model:{value:(_vm.isSearchWhileMoving),callback:function ($$v) {_vm.isSearchWhileMoving=$$v},expression:"isSearchWhileMoving"}})],1):_vm._e(),_c('WMButton',{class:['map-actions__back-button', { small: _vm.isNearbyMode }],attrs:{"icon-class-name":"fa-solid fa-arrow-left fa-xs","color":"accent"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('hideMap')}}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('filter.google_map.hide_map')))])])],1),_c('div',{staticClass:"overlays"},_vm._l((_vm.venues),function(venue){return _c('MapCard',{key:venue.venueId,attrs:{"data-venue":venue.venueId,"highlighted-map-card-venue-id":_vm.highlightedMapCardVenueId,"is-nearby-mode-enabled":_vm.isNearbyMode,"venue":venue},on:{"click":function($event){return _vm.onClickMarker($event)},"highlightSpaceCard":function($event){return _vm.$emit('highlightSpaceCard', $event)}}})}),1),(_vm.currentMapCenter.lat)?_c('GmapMap',{ref:"mapRef",style:({ width: '100%', height: '100%' }),attrs:{"center":_vm.isNearbyMode
          ? {
              lat: _vm.selectedVenue.latitude,
              lng: _vm.selectedVenue.longitude
            } || {}
          : _vm.currentMapCenter,"zoom":_vm.zoom,"options":_vm.mapOptions,"gestureHandling":"cooperative","map-type-id":"terrain"},on:{"dragstart":_vm.mapStartedMoving,"dragend":_vm.mapEndedMoving,"zoom_changed":function($event){return _vm.onMapZoomChanged($event)}}},_vm._l((_vm.getFilteredNearbyList),function(place,index){return _c('GmapMarker',{key:_vm.venues.length * 2 + index,attrs:{"opacity":_vm.isNearbyMode ? 1 : 0,"icon":{
          url: '/img/common/nearby-service-marker.svg'
        },"z-index":10,"position":_vm.getFilteredNearbyList.length > 0 && place && place.geometry.location,"clickable":false}})}),1):_vm._e(),(_vm.isNearbyMode)?_c('div',{staticClass:"map-detail-nearby"},[_c('div',{staticClass:"map-detail-nearby-info"},[(
            _vm.selectedVenue &&
            _vm.selectedVenue.images &&
            _vm.selectedVenue.images.length > 0
          )?_c('img',{staticClass:"map-detail-nearby-info-img",attrs:{"src":_vm.selectedVenue.images[0],"alt":"space","loading":"lazy"}}):_vm._e(),_c('div',{staticClass:"map-detail-nearby-info-label"},[_c('span',{staticClass:"map-detail-nearby-info-label-title"},[_vm._v(_vm._s(_vm.selectedVenue.venueName))]),_c('span',{staticClass:"map-detail-nearby-info-label-subtitle"},[_vm._v(_vm._s(_vm.$t('filter.google_map.features_ten_minutes')))])])]),_c('div',{staticClass:"map-detail-nearby-controls"},[_c('div',{staticClass:"map-detail-nearby-controls-back",on:{"click":function($event){$event.stopPropagation();return _vm.setNearbyMode(false)}}},[_c('i',{staticClass:"fa-solid fa-arrow-left"}),_c('span',{staticClass:"map-detail-nearby-controls-back-label"},[_vm._v(" "+_vm._s(_vm.$t('common.back'))+" ")])]),_c('div',{staticClass:"map-detail-nearby-controls-view"},[_c('div',{class:{
              'map-detail-nearby-controls-view-toggle': true,
              active: _vm.nearbyFilters.restaurant
            },on:{"click":function($event){return _vm.onNearbyFilterClick('restaurant')}}},[_c('i',{staticClass:"fa-solid fa-utensils"})]),_c('div',{class:{
              'map-detail-nearby-controls-view-toggle': true,
              active: _vm.nearbyFilters.parking
            },on:{"click":function($event){return _vm.onNearbyFilterClick('parking')}}},[_c('i',{staticClass:"fa-solid fa-square-parking"})]),_c('div',{class:{
              'map-detail-nearby-controls-view-toggle': true,
              active: _vm.nearbyFilters.cafe
            },on:{"click":function($event){return _vm.onNearbyFilterClick('cafe')}}},[_c('i',{staticClass:"fa-solid fa-mug-hot"})]),_c('div',{class:{
              'map-detail-nearby-controls-view-toggle': true,
              active: _vm.nearbyFilters.transit_station
            },on:{"click":function($event){return _vm.onNearbyFilterClick('transit_station')}}},[_c('i',{staticClass:"fa-solid fa-bus"})])])])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"price-filter"},[_c('span',{staticClass:"price-filter-title"},[_vm._v(" "+_vm._s(_vm.$t('filter.price.title'))+": ")]),_c('span',{staticClass:"price-filter-info"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.range[0],'EUR', { maximumFractionDigits: 0 }))+" – "+_vm._s(_vm._f("currency")(_vm.range[1],'EUR', { maximumFractionDigits: 0 }))+" ")]),_c('LineChart',{ref:"lineChartRef",staticClass:"price-filter-chart",attrs:{"chart-data":{
      labels: _vm.availablePriceRange,
      datasets: [
        {
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          data: _vm.selectedPriceRange
        },
        {
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          data: _vm.availablePriceRange
        }
      ]
    }}}),_c('vue-slider',{attrs:{"tooltip":"none","interval":_vm.sliderInterval,"enable-cross":false,"min":_vm.histogram && _vm.histogram.minPrice,"max":_vm.histogram && _vm.histogram.maxPrice,"rail-style":{ backgroundColor: '#A6DFF7', height: '1px' },"process-style":{ backgroundColor: '#0190CE', height: '1px' }},scopedSlots:_vm._u([{key:"dot",fn:function(ref){
    var focus = ref.focus;
return [_c('div',{class:['custom-dot', { focus: focus }]},[_vm._v("||")])]}}]),model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}}),_c('span',{staticClass:"price-filter__count"},[_vm._v(" "+_vm._s(_vm.$tc('filter.price.space', _vm.totalCount))+" ")]),_c('span',{staticClass:"price-filter__description"},[_vm._v(" "+_vm._s(_vm.$t('filter.price.description'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import SpaceCard from '@/Search/components/SpaceCard.vue';
import { defineComponent, computed, ref, onMounted, watch } from '@vue/composition-api';
import { DEFAULT_PAGE_SIZE, SortMethods } from '@/Search/search.const';
import EventBus from '@/shared/services/eventBus';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import PagePagination from '@/components/PagePagination.vue';
import { isEqual } from 'lodash';
export default defineComponent({
    components: {
        PagePagination,
        SpaceCard
    },
    props: {
        venues: {
            type: Array,
            default: () => []
        },
        similaritySuggestions: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        isMapHidden: {
            type: Boolean,
            default: false
        },
        highlightedSpaceCardVenueId: {
            type: Number,
            default: 0
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const sortingMethod = ref(SortMethods.RESET);
        const sortingCallback = ref(null);
        const initialShowAmount = ref(DEFAULT_PAGE_SIZE);
        const page = ref(null);
        const maxPage = computed(() => Math.ceil(spaceCards.value.length / initialShowAmount.value));
        const similaritiesFound = computed(() => {
            if (!props.similaritySuggestions)
                return [];
            const similarities = [];
            props.similaritySuggestions.cities?.map(citySuggestion => similarities.push({ value: citySuggestion, icon: 'fa-solid fa-city' }));
            props.similaritySuggestions.spaces?.map(spaceSuggestion => similarities.push({
                value: spaceSuggestion,
                icon: 'fa-solid fa-door-open'
            }));
            props.similaritySuggestions.venues?.map(venueSuggestion => similarities.push({
                value: venueSuggestion,
                icon: 'fa-solid fa-building'
            }));
            props.similaritySuggestions.regions?.map(regionSuggestion => similarities.push({
                value: regionSuggestion,
                icon: 'fa-solid fa-map-location-dot'
            }));
            return similarities;
        });
        const totalResultsFound = computed(() => {
            return spaceCards.value?.length || 0;
        });
        const getPaginatedResults = (cards) => {
            return cards.slice(initialShowAmount.value * page.value - initialShowAmount.value, initialShowAmount.value * page.value);
        };
        const spaceCards = computed(() => {
            const result = props.venues?.reduce((acc, { venueId, venueName, venueUrl, spaces, organisationRating, city }) => acc.concat(spaces
                ? spaces.map(space => ({
                    ...space,
                    organisationRating,
                    venueName,
                    venueId,
                    venueUrl,
                    cityName: city
                }))
                : []), []) || [];
            if (sortingCallback.value) {
                // Sort spaces if there is an incoming EventBus sorting callback
                result.sort((a, b) => sortingCallback.value(a, b, sortingMethod.value));
            }
            return result;
        });
        const visibleSpaceCards = computed(() => {
            return getPaginatedResults(spaceCards.value);
        });
        const searchList = ref(null);
        const location = computed(() => {
            const { lat, lng } = root.$route.query;
            return {
                lat,
                lng
            };
        });
        const isFiltersEnabled = computed(() => {
            return Object.keys(root.$route.query).some(item => !['lat', 'lng'].includes(item));
        });
        const getResults = () => {
            if (isFiltersEnabled.value) {
                return globalThis.$router.replace({ query: location.value });
            }
            const searchField = document.querySelector('input[type=search]');
            searchField?.focus();
            searchField?.select();
        };
        /**
         * Scroll to the top of the search results div
         */
        const scrollTop = () => {
            searchList.value?.scrollTo(0, 0);
        };
        watch(() => page.value, () => {
            if (page.value) {
                const newQuery = {
                    ...root.$route.query,
                    page: page.value === 1 ? undefined : page.value
                };
                globalThis.$router.replace({
                    query: newQuery
                });
                scrollTop();
            }
        }, { immediate: true });
        watch(() => totalResultsFound.value, () => {
            emit('totalResultsFound', totalResultsFound.value);
        }, { immediate: true });
        /**
         * Scroll to the top of the search results div when there is a new set of venues
         */
        watch(() => props.venues, (curr, prev) => {
            if (props.venues) {
                if (!isEqual(curr, prev) && prev) {
                    page.value = 1;
                }
                scrollTop();
            }
        }, { immediate: true, deep: true });
        /**
         * When user clicks on a suggestion, we want to update the search query and fetch new results.
         */
        const onSuggestionClick = (suggestion) => {
            root.$router.push({
                query: {
                    search: suggestion
                }
            });
        };
        onMounted(() => {
            page.value =
                root.$route.query.page && Number(root.$route.query.page) > 0
                    ? Number(root.$route.query.page)
                    : 1;
            Object.values(SortMethods).forEach(sortMethod => {
                EventBus.$on(sortMethod, cb => {
                    sortingMethod.value = sortMethod;
                    sortingCallback.value = cb;
                });
            });
        });
        return {
            isFiltersEnabled,
            location,
            maxPage,
            page,
            searchList,
            similaritiesFound,
            sortingMethod,
            spaceCards,
            totalResultsFound,
            visibleSpaceCards,
            getResults,
            onSuggestionClick,
            useGetLocalizedPath
        };
    }
});

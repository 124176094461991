import { defineComponent, computed, ref } from '@vue/composition-api';
import { VenueFindResponseHitsListItem } from '@/generated-types/graphql.types';
export default defineComponent({
    props: {
        venue: {
            type: VenueFindResponseHitsListItem,
            required: true
        },
        highlightedMapCardVenueId: {
            type: Number,
            default: 0
        },
        isNearbyModeEnabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const isMapCardHovered = ref(false);
        const startingPrice = computed(() => {
            return new Intl.NumberFormat('nl', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0
            }).format(props.venue.startingPrice.toFixed(0));
        });
        return { isMapCardHovered, startingPrice };
    }
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'map-card',
    {
      hovered:
        _vm.isMapCardHovered || _vm.highlightedMapCardVenueId === _vm.venue.venueId,
      hidden: _vm.isNearbyModeEnabled
    }
  ],attrs:{"data-venue":_vm.venue.venueId},on:{"mouseenter":function($event){[(_vm.isMapCardHovered = true), _vm.$emit('highlightSpaceCard', _vm.venue.venueId)]},"mouseleave":function($event){[(_vm.isMapCardHovered = false), _vm.$emit('highlightSpaceCard', undefined)]},"click":function($event){return _vm.$emit('click', _vm.venue.venueId)}}},[_c('span',{staticClass:"map-card__venue-price"},[_vm._v(_vm._s(_vm.startingPrice))])])}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue';
import MapCard from '@/Search/components/MapCard.vue';
import { defineComponent, onMounted, ref, watch, reactive, computed, watchEffect } from '@vue/composition-api';
// import { convertMapZoomToKilometers } from '@/Search/search.utils';
import useGoogleMap from '@/shared/composables/useGoogleMap';
const NEARBY_SEARCH_RADIUS = 500; // in meters
const DEFAULT_MAP_ZOOM = 8;
const NEARBY_MAP_ZOOM = 16;
export default defineComponent({
    components: {
        MapCard
    },
    props: {
        venues: {
            type: Array,
            default: () => []
        },
        location: {
            type: Object,
            default: () => { }
        },
        highlightedMapCardVenueId: {
            type: Number,
            default: 0
        },
        isOrganisationFilterEnabled: {
            type: Boolean,
            default: false
        },
        isMapHidden: {
            type: Boolean,
            default: false
        },
        isNearbyModeEnabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const google = ref(null);
        const mapRef = ref(null);
        const map = ref(null);
        const isNearbyMode = ref(false);
        const zoomBeforeNearbyModeEntering = ref(DEFAULT_MAP_ZOOM);
        const isSearchWhileMoving = ref(false);
        const zoom = ref(DEFAULT_MAP_ZOOM);
        const currentMapCenter = reactive({ lat: null, lng: null });
        const mapOptions = reactive({
            zoomControl: true,
            zoomControlOptions: {
                position: null
            },
            mapTypeControl: false,
            scaleControl: true,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false,
            disableDoubleClickZoom: false
        });
        const selectedVenueId = ref(undefined);
        const nearbyFilters = reactive({
            transit_station: false,
            restaurant: false,
            cafe: false,
            parking: false
        });
        const nearbyList = reactive({
            restaurant: null,
            transit_station: null,
            cafe: null,
            parking: null
        });
        const placeMarkers = ref({});
        const selectedVenueMarker = ref(null);
        const cityCircle = ref(null);
        const placesService = computed(() => new google.value.maps.places.PlacesService(map.value));
        const serviceAnchor = computed(() => new google.value.maps.Point(7, 26));
        const labelAnchor = computed(() => new google.value.maps.Point(-17, 30));
        /**
         * Watch for nearby mode state changes. When nearby mode is enabled, set map options and zoom.
         */
        watch(() => isNearbyMode.value, () => {
            if (isNearbyMode.value) {
                // Save map zoom setting before entering the nearby mode
                // This value is then used to restore the map zoom on nearby mode exit
                zoomBeforeNearbyModeEntering.value = zoom.value;
            }
            zoom.value = isNearbyMode.value
                ? NEARBY_MAP_ZOOM
                : map.value?.zoom || DEFAULT_MAP_ZOOM;
            mapOptions.disableDoubleClickZoom = isNearbyMode.value;
            mapOptions.scaleControl = !isNearbyMode.value;
            mapOptions.zoomControl = !isNearbyMode.value;
        }, { immediate: true });
        /**
         * Watch for organisation filter state. When organisation filter is enabled,
         * disable search while moving.
         */
        watchEffect(() => {
            if (props.isOrganisationFilterEnabled) {
                isSearchWhileMoving.value = false;
            }
        });
        /**
         * Sets initial map center
         */
        watch(() => props.location, () => {
            if (props.location) {
                currentMapCenter.lng = props.location.lng;
                currentMapCenter.lat = props.location.lat;
            }
        }, { immediate: true });
        /**
         * Fits map bounds to the venues array so that all venues are visible on the map
         */
        const fitMapBounds = () => {
            if (!props.venues.length || !google.value)
                return;
            const coordinatesList = [];
            props.venues.forEach(venue => coordinatesList.push(new google.value.maps.LatLng(venue.latitude, venue.longitude)));
            //  Create a new viewpoint bound
            let bounds = new google.value.maps.LatLngBounds();
            //  Go through each...
            for (let i = 0, LtLgLen = coordinatesList.length; i < LtLgLen; i++) {
                //  And increase the bounds to take this point
                bounds.extend(coordinatesList[i]);
            }
            map.value.fitBounds(bounds);
        };
        const drawOverlays = () => {
            props.venues.forEach(venue => {
                const position = new google.value.maps.LatLng(venue.latitude, venue.longitude);
                const MapOverlay = getMapOverlay(google.value.maps.OverlayView);
                new MapOverlay(position, map.value, onPositionUpdated, venue.venueId);
            });
        };
        /**
         * Watch the length of venues array. When it changes, fit map bounds.
         * Works only if there is no lat/lng query params in the URL.
         * This is needed to adjust map zoom when user types new search request into the search header input.
         * When lat/lng query params are present in the URL, we don't want to change map zoom (search while moving).
         */
        watch(() => props.venues, (newVenues, oldVenues) => {
            if (newVenues.length !== oldVenues.length) {
                drawOverlays();
                if (!root.$route.query.lat) {
                    fitMapBounds();
                }
            }
        });
        /**
         * Watch for map visibility changes. When map is visible, fit map bounds.
         */
        watch(() => props.isMapHidden, () => {
            if (!props.isMapHidden) {
                fitMapBounds();
            }
        }, { immediate: true });
        /**
         * Watch for screen resolution changes. For mobile screens always hide the map initially.
         * This is needed to prevent map from being displayed on mobile screens when user opens the search page.
         * For the rest screen sizes, fit map bounds.
         */
        watch(() => root.$mq.phone, () => {
            if (!root.$mq.phone) {
                fitMapBounds();
            }
            else {
                emit('hideMap');
            }
        }, { immediate: true });
        const isMapMoving = ref(false);
        const mapStartedMoving = () => {
            isMapMoving.value = true;
        };
        /**
         * Watch for map movements by user. Updates current map center, puts lat/lng query params into the URL,
         * and removes search query param from the URL.
         */
        const mapEndedMoving = () => {
            isMapMoving.value = false;
            if (!isSearchWhileMoving.value)
                return;
            // if search while moving is enabled
            const center = map.value.getCenter();
            const newQuery = {
                ...root.$route.query,
                lat: center.lat(),
                lng: center.lng(),
                search: undefined
            };
            if (newQuery === root.$route.query) {
                return;
            }
            globalThis.$router.replace({
                query: newQuery
            });
        };
        const onMapZoomChanged = newZoom => {
            if (newZoom && newZoom !== DEFAULT_MAP_ZOOM) {
                // TODO: Uncomment if we want to use zoom value for distance calculation
                // on map zoom change event, set new map center
                // const currentMapCenterLat = currentMapCenter.lat;
                // ...and emit an event with the new view distance in kilometers
                // const viewDistance = convertMapZoomToKilometers(
                //   newZoom,
                //   currentMapCenterLat || 0
                // );
                // emit('currentZoomDistance', viewDistance);
                // ... and save new zoom into variable
                zoom.value = newZoom;
            }
        };
        const onClickMarker = (venueId) => {
            selectedVenueId.value = venueId;
            emit('mapMarkerClicked', venueId);
        };
        const setNearbyMode = (isNearbyModeEnabled) => {
            isNearbyMode.value = isNearbyModeEnabled;
            if (!isNearbyModeEnabled) {
                // remove all nearby markers from the map
                if (selectedVenueMarker.value) {
                    selectedVenueMarker.value.setMap(null);
                }
                Object.values(placeMarkers.value).forEach(markerGroup => markerGroup.forEach(marker => marker.setMap(null)));
                if (cityCircle.value) {
                    cityCircle.value.setMap(null);
                }
                // restore map zoom
                zoom.value = zoomBeforeNearbyModeEntering.value;
                emit('nearbyClosed');
            }
            else {
                placeMarkers.value = {};
                const types = ['restaurant', 'cafe', 'parking', 'transit_station'];
                const requests = types.map(type => ({
                    location: {
                        lat: selectedVenue.value.latitude,
                        lng: selectedVenue.value.longitude
                    },
                    radius: NEARBY_SEARCH_RADIUS,
                    type: [type]
                }));
                // https://developers.google.com/maps/documentation/javascript/places?hl=nl#place_search_requests
                requests.forEach(request => placesService.value.nearbySearch(request, results => getNearbyPlaces(results, request.type[0])));
                if (cityCircle.value) {
                    cityCircle.value.setMap(null);
                }
                cityCircle.value = new google.value.maps.Circle({
                    strokeColor: '#009FE3',
                    strokeWeight: 1,
                    fillColor: '#D7F3FF',
                    fillOpacity: 0.3,
                    map: map.value,
                    center: {
                        lat: selectedVenue.value.latitude,
                        lng: selectedVenue.value.longitude
                    },
                    radius: NEARBY_SEARCH_RADIUS
                });
                const MarkerWithLabel = require('markerwithlabel')(google.value.maps);
                selectedVenueMarker.value = new MarkerWithLabel({
                    map: map.value,
                    icon: {
                        url: '/img/common/nearby-venue-marker.svg'
                    },
                    position: {
                        lat: selectedVenue.value.latitude,
                        lng: selectedVenue.value.longitude
                    },
                    labelContent: `<span>${selectedVenue.value.venueName}</span>`,
                    labelClass: 'nearby-venue-marker',
                    labelAnchor: labelAnchor.value,
                    zIndex: 10000
                });
            }
        };
        /**
         * Watch for nearby mode switch from SelectedVenue.vue component.
         */
        watch(() => props.isNearbyModeEnabled, () => {
            if (props.isNearbyModeEnabled) {
                selectedVenueId.value = props.highlightedMapCardVenueId;
                setNearbyMode(true);
            }
        });
        const { getNearbyPlaces, onNearbyFilterClick, getMapOverlay } = useGoogleMap(nearbyFilters, placeMarkers, nearbyList, google, map, serviceAnchor, labelAnchor);
        const getFilteredNearbyList = computed(() => {
            let result = [];
            if (nearbyFilters.restaurant) {
                result = result.concat(nearbyList.restaurant);
            }
            if (nearbyFilters.cafe) {
                result = result.concat(nearbyList.cafe);
            }
            if (nearbyFilters.transit_station) {
                result = result.concat(nearbyList.transit_station);
            }
            if (nearbyFilters.parking) {
                result = result.concat(nearbyList.parking);
            }
            return result;
        });
        const selectedVenue = computed(() => {
            const index = props.venues.findIndex(venue => venue.venueId === selectedVenueId.value);
            if (index < 0) {
                setNearbyMode(false);
                return;
            }
            return props.venues[index];
        });
        /**
         * Updates the position of the map card on the map or hides it if the position is outside the map bounds
         */
        const onPositionUpdated = (left, top, venueId, isPositionInsideMapBounds) => {
            const element = document.querySelector(`.map-card[data-venue="${venueId}"]`);
            if (element) {
                element.style.left = left;
                element.style.top = top;
                element.style.opacity =
                    isPositionInsideMapBounds && !isNearbyMode.value && !isMapMoving.value
                        ? 1
                        : 0;
            }
        };
        /**
         * Loads Google Maps API and sets default map options
         */
        onMounted(async () => {
            // https://diegoazh.github.io/gmap-vue/#the-three-main-utilities
            google.value = await Vue.$gmapApiPromiseLazy();
            await mapRef.value.$mapPromise;
            map.value = mapRef.value.$mapObject;
            fitMapBounds();
            drawOverlays();
        });
        return {
            currentMapCenter,
            getFilteredNearbyList,
            isNearbyMode,
            isSearchWhileMoving,
            mapRef,
            mapOptions,
            nearbyFilters,
            selectedVenue,
            zoom,
            mapEndedMoving,
            mapStartedMoving,
            onClickMarker,
            setNearbyMode,
            onNearbyFilterClick,
            onMapZoomChanged
        };
    }
});

import { defineComponent, ref, watch } from '@vue/composition-api';
export default defineComponent({
    props: {
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 99
        },
        value: {
            type: Number,
            default: 0
        }
    },
    setup(props, { emit }) {
        let attendees = ref(1);
        const onChange = (value) => {
            attendees.value = value;
            emit('input', value);
        };
        watch(() => props.value, () => (attendees.value = props.value || props.min), { immediate: true });
        return {
            attendees,
            onChange
        };
    }
});

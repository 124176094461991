import { defineComponent, computed, reactive, watch, onBeforeUnmount } from '@vue/composition-api';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import ListItem from '@/uikit/ListItem.vue';
export default defineComponent({
    components: {
        ListItem
    },
    props: {
        type: {
            type: String,
            default: null
        },
        size: {
            type: String,
            default: 'small'
        },
        value: {
            type: Array,
            default: () => []
        },
        demands: {
            type: Array,
            default: null
        },
        scrollStatus: {
            type: Boolean
        },
        columnView: {
            type: Boolean,
            default: false
        }
    },
    setup({ value, demands, scrollStatus }, { emit }) {
        const normalized = computed(() => groupBy(demands, item => item.name));
        let features = reactive({});
        watch(() => normalized.value, () => {
            features = reactive(mapValues(normalized.value, ([{ id }]) => value && value.includes(id)));
        }, { immediate: true });
        const onChange = (value, name) => {
            features[name] = value;
            const results = Object.entries(features)
                .filter(([_, value]) => value)
                .map(([key]) => normalized.value?.[key]?.[0]?.id);
            emit('input', results);
        };
        const scrollList = e => {
            emit('onScroll', {
                bottom: e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight >
                    1,
                top: e.target.scrollTop !== 0
            });
        };
        onBeforeUnmount(() => {
            emit('onScroll', {
                bottom: true,
                top: false
            });
        });
        return {
            features,
            onChange,
            scrollList
        };
    }
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.demands)?_c('ul',{class:['demand-list-items', _vm.size, { 'column-view': _vm.columnView }],on:{"scroll":_vm.scrollList}},_vm._l((_vm.demands),function(ref){
var name = ref.name;
var id = ref.id;
var available = ref.available;
return _c('ListItem',{key:id,class:[
      'demand-list__item',
      { 'demand-list__item--disabled': !available }
    ],scopedSlots:_vm._u([{key:"main-content",fn:function(){
    var _obj;
return [_c('WMCheckbox',{class:[
          'demand-list__checkbox ',
          ( _obj = {}, _obj['demand-list__checkbox--disabled'] = !available, _obj )
        ],attrs:{"disabled":!available,"value":_vm.features[name]},on:{"input":function($event){return _vm.onChange($event, name)}}},[_c('div',{staticClass:"demand-list-checkbox"},[_c('div',{staticClass:"demand-list-checkbox__content"},[_c('p',{staticClass:"demand-list-checkbox__title"},[_vm._v(" "+_vm._s(_vm.$t(("filter." + _vm.type + "." + name + ".title")))+" ")]),(_vm.size === 'big')?_c('p',{staticClass:"demand-list-checkbox__description"},[_vm._v(" "+_vm._s(_vm.$t(("filter." + _vm.type + "." + name + ".description")))+" ")]):_vm._e()]),(_vm.size === 'big')?_c('div',{staticClass:"demand-list-checkbox__image"},[_c('i',{class:("icon-config_" + (name.toLowerCase()))})]):_vm._e()])])]},proxy:true}],null,true)})}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
import SelectedVenue from '@/Search/components/SelectedVenue.vue';
import FoundResults from '@/Search/components/FoundResults.vue';
import Map from '@/Search/Map.vue';
import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import { DEFAULT_COUNTRY_COORDINATES } from '@/Search/search.const';
export default defineComponent({
    components: {
        FoundResults,
        SelectedVenue,
        Map
    },
    props: {
        venues: {
            type: Array,
            default: () => []
        },
        similaritySuggestions: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        isOrganisationFilterEnabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const { root } = context;
        let isMobileMapMode = ref(false);
        let isSelectedVenueVisible = ref(false);
        let isSearchResultsVisible = ref(true);
        let highlightedSpaceCardVenueId = ref(0);
        let highlightedMapCardVenueId = ref(0);
        const selectedVenueId = ref(undefined);
        const selectedVenue = computed(() => {
            return props.venues?.find(venue => venue.venueId === selectedVenueId.value);
        });
        const location = computed(() => {
            let { lat, lng } = root.$route.query || {};
            if (lat && lng) {
                lat = Number(lat);
                lng = Number(lng);
            }
            else {
                // Default to country coordinates
                lat = DEFAULT_COUNTRY_COORDINATES.lat;
                lng = DEFAULT_COUNTRY_COORDINATES.lng;
            }
            return {
                lat,
                lng
            };
        });
        const isMapVisible = ref(true);
        /**
         * Toggle FoundResults.vue visibility based on whether a venue is selected
         */
        watch(() => selectedVenue.value, () => {
            if (selectedVenue.value) {
                isSelectedVenueVisible.value = true;
            }
            else {
                isSelectedVenueVisible.value = false;
            }
        });
        const isNearbyModeEnabled = ref(false);
        /**
         * When map marker is clicked on small devices, hide map and show selected venue card.
         */
        const onMapMarkerClick = (venueId) => {
            if (!root.$mq.desktop) {
                setTimeout(() => {
                    selectedVenueId.value = venueId;
                    isMapVisible.value = false;
                    isSelectedVenueVisible.value = true;
                    isSearchResultsVisible.value = false;
                }, 500);
            }
            else {
                selectedVenueId.value = venueId;
                isSelectedVenueVisible.value = true;
                isSearchResultsVisible.value = false;
            }
        };
        return {
            highlightedMapCardVenueId,
            highlightedSpaceCardVenueId,
            isMapVisible,
            isMobileMapMode,
            isNearbyModeEnabled,
            isSearchResultsVisible,
            isSelectedVenueVisible,
            location,
            selectedVenueId,
            selectedVenue,
            onMapMarkerClick
        };
    }
});

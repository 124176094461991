import FilterOption from '@/Search/components/FilterOption.vue';
import TimeFilter from '@/Search/components/TimeFilter.vue';
import PriceFilter from '@/Search/components/PriceFilter.vue';
import DemandList from '@/Search/components/DemandList.vue';
import FilterModal from '@/Search/components/FilterModal.vue';
import FilterForm from '@/Search/components/FilterForm.vue';
import FilterFooter from '@/Search/components/FilterFooter.vue';
import FilterPopover from '@/Search/components/FilterPopover.vue';
import AttendeesFilter from '@/Search/components/AttendeesFilter.vue';
import FilterAccordionHeading from '@/Search/components/FilterAccordionHeading.vue';
import Accordion from '@/components/Accordion.vue';
import SearchSorting from '@/Search/components/SearchSorting.vue';
import { useSpaceFeaturesQuery, useVenueFeaturesQuery, useVenueServicesQuery, useSpaceServicesQuery, useConfigurationsQuery, useSpaceCategoriesQuery, useGetCollectionNameQuery } from '@/generated-types/graphql.types';
import { computed, watch, reactive, defineComponent, ref } from '@vue/composition-api';
import { formatTimeRange } from '@/Search/search.utils';
import { parseObject } from '@/util/parse';
import EventBus from '@/shared/services/eventBus';
import { Portal } from 'portal-vue';
import ListItem from '@/uikit/ListItem.vue';
import { cleanObjectKeys } from '@/util/globalHelpers';
import { isEqual } from 'lodash';
const initFilters = {
    attendees: undefined,
    categories: [],
    facilities: [],
    features: [],
    configurations: [],
    isDirectBooking: undefined,
    hasFavoritesOnly: undefined,
    isSocial: undefined,
    isPostPayment: undefined,
    organisation: undefined,
    tag: undefined
};
const filterOptions = {
    time: {
        slotFrom: null,
        slotTo: null
    },
    attendees: {
        attendees: undefined
    },
    price: {
        priceFrom: null,
        priceTo: null
    },
    services: {
        facilities: []
    },
    features: {
        features: []
    },
    configurations: {
        configurations: []
    }
};
export default defineComponent({
    components: {
        ListItem,
        FilterOption,
        FilterForm,
        FilterModal,
        FilterFooter,
        FilterPopover,
        AttendeesFilter,
        TimeFilter,
        PriceFilter,
        DemandList,
        Accordion,
        FilterAccordionHeading,
        SearchSorting,
        Portal
    },
    props: {
        priceHistogram: {
            type: Object,
            default: null
        },
        availableFilters: {
            type: Object,
            default: null
        },
        totalResults: {
            type: Number,
            default: 0
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        let filter = reactive({
            categories: new Array(),
            features: new Array(),
            facilities: new Array(),
            isDirectBooking: false,
            isFavorite: false,
            isSocial: false,
            isPostPayment: false,
            organisation: '',
            attendees: undefined,
            time: reactive({
                slotFrom: null,
                slotTo: null
            }),
            price: reactive({
                priceFrom: null,
                priceTo: null
            }),
            configurations: new Array()
        });
        const orgName = ref(Array.isArray(root.$route.query.organisation)
            ? root.$route.query.organisation.join(',')
            : root.$route.query.organisation || '');
        const isTimeFilter = computed(() => {
            return Boolean(filter.time.slotFrom || filter.time.slotTo);
        });
        const isPriceFilter = computed(() => {
            return !!(filter.price.priceFrom && filter.price.priceTo);
        });
        const handleButtonValue = (filterType) => {
            const queryParamFrom = filterType === 'price' ? 'priceFrom' : 'slotFrom';
            const queryParamTo = filterType === 'price' ? 'priceTo' : 'slotTo';
            if (!root.$route.query[queryParamFrom]) {
                Object.assign(filter[filterType], filterOptions[filterType]);
            }
            else if (root.$route.query[queryParamFrom] !== filter[filterType][queryParamFrom]) {
                filter[filterType][queryParamFrom] = root.$route.query[queryParamFrom];
                filter[filterType][queryParamTo] = root.$route.query[queryParamTo];
            }
        };
        watch(() => root.$route.query, () => {
            if (root.$route.query) {
                Object.assign(filter, {
                    ...initFilters,
                    price: {
                        priceTo: null,
                        priceFrom: null
                    },
                    time: {
                        slotTo: null,
                        slotFrom: null
                    }
                });
                Object.entries(parseObject(root.$route.query)).forEach(([key, value]) => {
                    if (Object.keys(filter.time).includes(key)) {
                        filter.time[key] = value;
                        return;
                    }
                    if (Object.keys(filter.price).includes(key)) {
                        filter.price[key] = value;
                        return;
                    }
                    filter[key] = value;
                    if (key == 'organisation') {
                        emit('organisationFilterChanged', !!value);
                    }
                });
            }
        }, { immediate: true });
        const onFilter = (values) => {
            const newQuery = {
                ...root.$route.query,
                ...values
            };
            if (!isEqual(cleanObjectKeys(newQuery), root.$route.query)) {
                globalThis.$router.replace({
                    query: newQuery
                });
            }
        };
        const reset = () => {
            EventBus.$emit('filtersResetButtonClicked');
            onFilter({
                ...initFilters,
                slotFrom: undefined,
                slotTo: undefined,
                priceFrom: undefined,
                priceTo: undefined,
                sort: undefined
            });
        };
        // Why is below not a single graphql query?
        const { result: spaceFeatures } = useSpaceFeaturesQuery({
            clientId: 'legacy'
        });
        const { result: venueFeatures } = useVenueFeaturesQuery({
            clientId: 'legacy'
        });
        const { result: spaceServices } = useSpaceServicesQuery({
            clientId: 'legacy'
        });
        const { result: venueServices } = useVenueServicesQuery({
            clientId: 'legacy'
        });
        const { result: configurationsList } = useConfigurationsQuery({
            clientId: 'legacy'
        });
        const { result: activitiesList } = useSpaceCategoriesQuery({
            clientId: 'legacy'
        });
        const { result: collectionNameResponse } = useGetCollectionNameQuery({
            slug: orgName.value
        }, () => ({
            enabled: !!orgName.value
        }));
        // Why is the above not a single graphql query?
        const sortObjectsByBoolString = (obj1, obj2, byString, byBool) => {
            const nameOrder = obj1[byString] === obj2[byString]
                ? 0
                : obj1[byString] < obj2[byString]
                    ? -1
                    : 1;
            if ((obj1[byBool] && obj2[byBool]) || (!obj1[byBool] && !obj2[byBool])) {
                return nameOrder;
            }
            else if (obj1[byBool]) {
                return -1;
            }
            else {
                return 1;
            }
        };
        const configurations = computed(() => configurationsList?.value?.getApiConfiguration
            ?.map(item => ({
            id: item?.configurationId,
            name: item?.configurationType?.toLowerCase(),
            available: props.availableFilters?.configurations?.includes(item?.configurationId || 0)
        }))
            .sort((a, b) => {
            return sortObjectsByBoolString(a, b, 'name', 'available');
        }));
        const activities = computed(() => activitiesList?.value?.spaceCategories?.map(item => ({
            id: item?.categoryId,
            name: item?.categoryName,
            available: props.availableFilters?.categories?.includes(item?.categoryId || 0)
        })));
        const features = computed(() => new Array()
            .concat(spaceFeatures?.value?.getSpaceFeatures ?? [], venueFeatures?.value?.getVenueFeatures ?? [])
            .map(item => ({
            id: item?.featureId,
            name: item?.featureName?.toLowerCase(),
            available: props.availableFilters?.features?.includes(item?.featureId)
        }))
            .sort((a, b) => {
            return sortObjectsByBoolString(a, b, 'name', 'available');
        }));
        const facilities = computed(() => new Array()
            .concat(spaceServices?.value?.getApiServiceSpace ?? [], venueServices?.value?.getApiServiceVenue ?? [])
            .map(item => ({
            id: item?.systemServiceId,
            name: item?.systemServiceName?.toLowerCase(),
            available: props.availableFilters?.facilities?.includes(item?.systemServiceId)
        }))
            .sort((a, b) => {
            return sortObjectsByBoolString(a, b, 'name', 'available');
        }));
        const organisationSwitch = (event) => {
            if (event) {
                onFilter({
                    organisation: orgName.value
                });
                emit('SearchFilters.vue organisationFilterChanged', true);
            }
            else {
                onFilter({
                    organisation: undefined
                });
                emit('organisationFilterChanged', false);
            }
            return undefined;
        };
        const mq = computed(() => root.$mq);
        const onResetFilter = (value) => {
            onFilter({
                ...filterOptions[value]
            });
        };
        const scrollShadow = reactive({
            top: false,
            bottom: true
        });
        const onScroll = ({ bottom, top }) => {
            scrollShadow.bottom = bottom;
            scrollShadow.top = top;
        };
        return {
            activities,
            collectionName: computed(() => collectionNameResponse.value?.get_collection.collection_name),
            configurations,
            facilities,
            features,
            filter,
            isPriceFilter,
            isTimeFilter,
            mq,
            orgName,
            scrollShadow,
            formatTimeRange,
            handleButtonValue,
            onFilter,
            onResetFilter,
            onScroll,
            organisationSwitch,
            reset
        };
    }
});

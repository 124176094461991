export const parseObject = (obj) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        try {
            if (Array.isArray(obj)) {
                return [...acc, JSON.parse(value)];
            }
            else {
                if (Array.isArray(value) && value.length === 1) {
                    throw new Error(''); // workaround as the array with one element is not parsed correctly
                }
                else {
                    return { ...acc, [key]: JSON.parse(value) };
                }
            }
        }
        catch {
            return Array.isArray(obj)
                ? [...acc, Array.isArray(value) ? parseObject(value) : value]
                : {
                    ...acc,
                    [key]: Array.isArray(value) ? parseObject(value) : value
                };
        }
    }, Array.isArray(obj) ? [] : {});
};

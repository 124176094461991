import { Line, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp], // used to update dynamically without reset chart
  data() {
    return {
      options: {
        legend: {
          display: false
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        elements: {
          line: {
            tension: 0
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false
              },
              ticks: {
                display: false
              }
            }
          ]
        },
        tooltips: {
          enabled: false
        }
      }
    };
  },
  mounted() {
    this.renderLineChart();
  },
  computed: {},
  methods: {
    renderLineChart() {
      this.renderChart(
        {
          labels: this.chartData.labels,
          datasets: [
            {
              ...this.chartData.datasets[0],
              backgroundColor: '#A6DFF7',
              borderColor: '#A6DFF7'
            },
            {
              ...this.chartData.datasets[1],
              backgroundColor: '#D7F3FF',
              borderColor: '#D7F3FF'
            }
          ]
        },
        this.options
      );
    }
  }
};

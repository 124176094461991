import LineChart from '@/Search/components/LineChart';
import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import inRange from 'lodash/inRange';
export default defineComponent({
    components: {
        LineChart
    },
    props: {
        value: {
            type: Object,
            default: null
        },
        histogram: {
            type: Object,
            default: null
        }
    },
    setup(props, { emit }) {
        let selectedPriceRange = ref(undefined);
        const range = computed({
            get: () => [
                props.value.priceFrom < props.histogram?.minPrice
                    ? props.histogram?.minPrice
                    : props.value.priceFrom,
                props.value.priceTo !== null &&
                    props.value.priceTo < props.histogram?.maxPrice
                    ? props.value.priceTo
                    : props.histogram?.maxPrice
            ],
            set: ([from, to]) => {
                const priceTo = to > props.histogram?.maxPrice ? props.histogram?.maxPrice : to;
                const priceFrom = from < props.histogram?.minPrice ? props.histogram?.minPrice : from;
                emit('input', {
                    priceTo,
                    priceFrom
                });
            }
        });
        const availablePriceRange = computed(() => props.histogram?.bins?.map(bin => bin?.count));
        watch(range, ([priceFrom, priceTo]) => {
            selectedPriceRange.value = props.histogram?.bins?.map(bin => bin?.to &&
                (inRange(bin?.to, priceTo, priceFrom) || bin?.to === priceTo)
                ? bin?.count
                : null);
        }, { immediate: true });
        const totalCount = computed(() => selectedPriceRange?.value?.reduce((acc, item) => (item ? acc + item : acc), 0));
        // https://github.com/NightCatSama/vue-slider-component/issues/352
        const sliderInterval = 0.01;
        return {
            range,
            availablePriceRange,
            selectedPriceRange,
            totalCount,
            sliderInterval
        };
    }
});

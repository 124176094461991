import FooterBlock from '@/shared/modules/footer/Footer.vue';
import SearchFilters from '@/Search/SearchFilters.vue';
import SearchResults from '@/Search/SearchResults.vue';
import { useSearchMutation } from '@/generated-types/graphql.types';
import { computed, defineComponent, ref, watch, onUnmounted } from '@vue/composition-api';
import { dateString } from '@/util/dates';
import { parseObject } from '@/util/parse';
import { PortalTarget } from 'portal-vue';
import { useMeta } from '@/shared/composables/useMeta';
import { withAuthMutationHook } from '@/util/hooks';
import { SITE_URL } from '@/App.vue';
import { SearchRoutes } from '@/Search/search.const';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import useStructuredData from "@/shared/composables/useStructuredData";
import EventBus from "@/shared/services/eventBus";
const searchMutation = withAuthMutationHook(useSearchMutation);
export default defineComponent({
    components: {
        SearchFilters,
        SearchResults,
        FooterBlock,
        PortalTarget
    },
    setup(_, context) {
        const { root } = context;
        const venues = ref(null);
        const priceHistogram = ref(undefined);
        const availableFilters = ref(undefined);
        const similaritySuggestions = ref(null);
        const totalResultsFound = ref(0);
        const hasQueryParams = computed(() => !!Object.keys(root.$route.query).length);
        const { injectStructuredData, ejectStructuredData } = useStructuredData();
        const schemaSearchInfo = computed(() => {
            return {
                '@context': 'https://schema.org',
                '@graph': []
            };
        });
        EventBus.$on('onBreadcrumbsReady', data => {
            injectData(data);
        });
        const injectData = (structuredData) => {
            const data = schemaSearchInfo.value;
            data['@graph'].push(structuredData);
            injectStructuredData({
                '@context': 'https://schema.org',
                ...data
            });
        };
        onUnmounted(() => {
            ejectStructuredData();
        });
        useMeta({
            title: root.$i18n.t('meta.search.title_tag').toString(),
            description: root.$i18n.t('meta.search.description_tag').toString(),
            keywords: root.$i18n.t('meta.general.meta_keywords', {
                returnObjects: true
            }),
            noIndex: false,
            url: SITE_URL + useGetLocalizedPath(SearchRoutes.SEARCH)
        });
        watch(() => hasQueryParams.value, () => {
            useMeta({
                noIndex: hasQueryParams.value
            });
        }, { immediate: true });
        const { mutate, loading } = searchMutation({
            clientId: 'legacy',
            fetchPolicy: 'no-cache'
        });
        const variables = computed(() => {
            const { attendees, configurations, facilities, hasFavoritesOnly, isDirectBooking, isSocial, isPostPayment, organisation, features, categories, slotFrom, slotTo, priceFrom, priceTo, lat, lng, tag, search } = root.$route.query || {};
            return {
                input: {
                    filters: {
                        attendees,
                        categories,
                        collectionSlug: organisation,
                        configurations,
                        facilities,
                        features,
                        hasFavoritesOnly,
                        isDirectBooking,
                        isSocial,
                        isPostPayment,
                        priceFrom,
                        priceTo,
                        search,
                        slotFrom: slotFrom && dateString(+slotFrom),
                        slotTo: slotTo && dateString(+slotTo),
                        tag
                    },
                    search: lat && lng
                        ? {
                            location: {
                                latitude: lat,
                                longitude: lng
                            }
                        }
                        : undefined
                }
            };
        });
        watch(() => variables.value, (newVariables, oldVariables) => {
            if (newVariables &&
                oldVariables &&
                JSON.stringify(newVariables) === JSON.stringify(oldVariables))
                return;
            if (newVariables && !root.$route.query.authPopup) {
                const mutationInput = {
                    input: {
                        filters: parseObject(newVariables.input.filters)
                    }
                };
                if (newVariables.input.search && newVariables.input.search.location) {
                    mutationInput.input.search = {
                        location: parseObject(newVariables.input.search.location)
                    };
                }
                mutate(mutationInput).then(result => {
                    venues.value =
                        result?.data?.mutationViewerApiKey?.searchVenues?.hits;
                    priceHistogram.value =
                        result?.data?.mutationViewerApiKey?.searchVenues?.priceHistogram;
                    availableFilters.value =
                        result?.data?.mutationViewerApiKey?.searchVenues?.availableFilters;
                    similaritySuggestions.value =
                        result?.data?.mutationViewerApiKey?.searchVenues?.similaritySuggestions;
                });
            }
        }, { immediate: true });
        const isOrganisationFilterEnabled = ref(false);
        const onOrganisationFilterChanged = (organisationFilterEnabled) => {
            isOrganisationFilterEnabled.value = organisationFilterEnabled;
        };
        return {
            availableFilters,
            isOrganisationFilterEnabled,
            loading,
            priceHistogram,
            similaritySuggestions,
            totalResultsFound,
            venues,
            onOrganisationFilterChanged
        };
    }
});
